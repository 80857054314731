* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

header {
  width: 100%;
}

.body {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.card {
  overflow: hidden;
  max-height: 80px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.card-displayed {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
